import React, { FC } from "react";
import classNames from "classnames";

import { getThumbnailFromUrl } from "@lib/utils";

import FilePreview from "@components/FilePreview";
import RemoteFavicon from "@components/FilesAndLinks/RemoteFavicon";
import FileIcon from "@components/Icons/FileIcon";
import OutcomeIcon from "@components/Outcomes/OutcomeIcon";

import {
  ArtifactStatusIconProps,
  ArtifactStatusTextProps,
  SizeStypeType,
} from "./types";
import { artifactStatusUtils } from "./utils";

export * from "./utils";

const ArtifactStatusIcon: FC<ArtifactStatusIconProps> = ({
  type,
  status,
  className,
  variant = "default",
  url,
  size = "default",
  hideStatus = false,
  iconWrapClassName,
  iconClassName,
  iconUrl,
  outcome,
}) => {
  const { icon: ArtifactIcon, statusIcons } = artifactStatusUtils[type];
  const { icon: StatusIcon, ...statusStyle } =
    statusIcons[status] || statusIcons["noStatus"] || null;

  const isDefault = variant === "default";
  const isOnlyIcon = variant === "status-icon";
  const isStatusDraft = ["draft", "open"].includes(status);

  const sizes: SizeStypeType = {
    default: {
      container: "w-12 h-12",
      iconWrapper: "w-10 h-10",
      icon: "w-6 h-6",
    },
    md: {
      container: "w-8 h-8",
      iconWrapper: "w-8 h-8",
      icon: "w-5 h-5",
    },
    sm: {
      container: "w-6 h-6",
      iconWrapper: "w-5 h-5",
      icon: "w-4 h-4",
    },
  };
  const sizeStyles = sizes[size];

  const renderArtifactIcon = () => {
    if (iconUrl) {
      return (
        <RemoteFavicon
          iconUrl={getThumbnailFromUrl(iconUrl)}
          placeholderIcon={<ArtifactIcon className="w-6 h-6" />}
          className="w-6 h-6"
        />
      );
    }
    if (url)
      return (
        <FilePreview
          url={getThumbnailFromUrl(url, 50)}
          size={50}
          fallback={<FileIcon />}
          imgClassName="p1"
          className={classNames(
            sizeStyles.container,
            isDefault && "rounded-lg",
            "overflow-hidden"
          )}
        />
      );
    return isDefault && <ArtifactIcon className="w-6 h-6" />;
  };

  const renderStatus = () => {
    if (hideStatus) return null;

    const iconStyle = classNames(isOnlyIcon && sizeStyles.icon, iconClassName);
    return (
      <span
        className={classNames(
          "block rounded-full",
          isDefault && "absolute -bottom-2 -right-2 w-6 h-6",
          isOnlyIcon &&
            `${sizeStyles.iconWrapper} flex items-center justify-center`,
          statusStyle.bgColor ? statusStyle?.bgColor : null,
          statusStyle.iconColor ? statusStyle.iconColor : null,
          iconWrapClassName
        )}
      >
        {outcome ? (
          <OutcomeIcon
            outcome={outcome}
            className={classNames(!isDefault && "p-2")}
            iconClassName={classNames(isDefault && "p-1")}
          />
        ) : (
          <StatusIcon className={iconStyle} />
        )}
      </span>
    );
  };

  return (
    <span
      className={classNames(
        "relative flex-none flex items-center justify-center",
        sizeStyles.container,
        isDefault && "rounded-lg",
        isDefault && (isStatusDraft ? "bg-foreground/7" : "bg-background"),
        className
      )}
    >
      {renderArtifactIcon()}
      {renderStatus()}
    </span>
  );
};

export const ArtifactStatusText: FC<ArtifactStatusTextProps> = ({
  type,
  status,
  className,
  size = "default",
}) => {
  const { statusColor, statusLabel } =
    artifactStatusUtils[type].statusIcons[status || "noStatus"];
  const sizeStyle = {
    default: "text-base",
    sm: "text-sm",
    xs: "text-xs",
  };
  return (
    <p
      className={classNames(
        "font-medium",
        sizeStyle[size],
        statusColor,
        className
      )}
    >
      {statusLabel}
    </p>
  );
};

export default ArtifactStatusIcon;
