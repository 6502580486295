import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const SparkleIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M13.333 1.31a1.333 1.333 0 00-2.666.023v.139c0 2.2-1.134 4.508-2.952 6.295s-4.166 2.9-6.382 2.9a1.333 1.333 0 100 2.666c2.233 0 4.578 1.13 6.39 2.943 1.814 1.813 2.944 4.158 2.944 6.39a1.333 1.333 0 102.666 0c0-2.232 1.13-4.577 2.943-6.39 1.813-1.813 4.158-2.943 6.39-2.943a1.333 1.333 0 000-2.666c-2.213 0-4.536-1.11-6.343-2.897-1.807-1.786-2.95-4.103-2.989-6.321v-.14z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default SparkleIcon;
