import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" fill="white" />
      <path
        d="M8 8C8 6.89543 8.89543 6 10 6H36C44.8366 6 52 13.1634 52 22V56C52 57.1046 51.1046 58 50 58H10C8.89543 58 8 57.1046 8 56V8Z"
        fill="#DEE5F0"
      />
      <rect x="14" y="14" width="20" height="4" rx="1" fill="#BFCCE2" />
      <rect x="14" y="22" width="32" height="4" rx="1" fill="#BFCCE2" />
      <rect x="14" y="30" width="32" height="4" rx="1" fill="#BFCCE2" />
      <rect x="14" y="38" width="32" height="4" rx="1" fill="#BFCCE2" />
      <rect x="14" y="46" width="32" height="4" rx="1" fill="#BFCCE2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3843 43.2128C42.0472 44.9632 39.1447 46 36 46C28.268 46 22 39.732 22 32C22 24.268 28.268 18 36 18C43.732 18 50 24.268 50 32C50 35.1447 48.9632 38.0472 47.2128 40.3843L54.7268 47.8984C55.5079 48.6794 55.5079 49.9458 54.7268 50.7268C53.9458 51.5079 52.6794 51.5079 51.8984 50.7268L44.3843 43.2128ZM46 32C46 37.5228 41.5228 42 36 42C30.4772 42 26 37.5228 26 32C26 26.4772 30.4772 22 36 22C41.5228 22 46 26.4772 46 32Z"
        fill="#002566"
      />
    </svg>
  );
};

export default Icon;
