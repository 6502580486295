import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="6" width="2" height="7" rx="0.5" fill="white" />
      <rect x="11" y="15" width="2" height="2" rx="0.5" fill="white" />
    </svg>
  );
};

export default Icon;
