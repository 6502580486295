import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 9.08098L12 6.12785L17.25 9.08098V14.909L12 17.8621L6.75 14.909V9.08098ZM12 4.12L19 8.0575V15.9325L12 19.87L5 15.9325V8.0575L12 4.12ZM12.875 11.995C12.875 12.4782 12.4832 12.87 12 12.87C11.5168 12.87 11.125 12.4782 11.125 11.995C11.125 11.5117 11.5168 11.12 12 11.12C12.4832 11.12 12.875 11.5117 12.875 11.995ZM14.625 11.995C14.625 13.4447 13.4497 14.62 12 14.62C10.5503 14.62 9.375 13.4447 9.375 11.995C9.375 10.5452 10.5503 9.37 12 9.37C13.4497 9.37 14.625 10.5452 14.625 11.995Z"
      />
    </svg>
  );
};

export default Icon;
