import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#BFCCE2" />
      <path
        d="M17.8609 11.6464C18.0561 11.8417 18.0561 12.1583 17.8609 12.3535L13.6182 16.5962C13.423 16.7915 13.1064 16.7915 12.9111 16.5962L12.204 15.8891C12.0088 15.6938 12.0088 15.3772 12.204 15.182L14.386 13L14.3955 12.9926L6.50732 12.9926C6.23118 12.9926 6.00732 12.7688 6.00732 12.4926L6.00732 11.4926C6.00732 11.2165 6.23118 10.9926 6.50732 10.9926L14.3786 10.9926L12.204 8.81801C12.0088 8.62275 12.0088 8.30617 12.204 8.11091L12.9111 7.4038C13.1064 7.20854 13.423 7.20854 13.6182 7.4038L17.8609 11.6464Z"
        fill="#00338C"
      />
    </svg>
  );
};

export default Icon;
