import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C10.3431 5 9 6.34315 9 8V9C7.89543 9 7 9.89543 7 11V16.5C7 16.7761 7.22386 17 7.5 17H16.5C16.7761 17 17 16.7761 17 16.5V11C17 9.89543 16.1046 9 15 9V8C15 6.34315 13.6569 5 12 5ZM13 9V8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V9H13ZM9 11V15H15V11H9Z"
      />
    </svg>
  );
};

export default Icon;
