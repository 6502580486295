import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M17 11H7v7h10v-7zM7 9a2 2 0 00-2 2v8.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V11a2 2 0 00-2-2H7z"
        clipRule="evenodd"
      ></path>
      <path d="M15 9V8a3 3 0 10-6 0v1H7V8a5 5 0 0110 0v1h-2z"></path>
    </svg>
  );
};

export default Icon;
