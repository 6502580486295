import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DocDraftIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 3.99807V3H7V1H5C3.89543 1 3 1.89543 3 3V3.99807H5Z" />
      <path d="M5 14H3V10H5V14Z" />
      <path d="M13 3V1H15C16.0897 1 17.1173 1.29233 18.0019 1.80419L17.0003 3.53531C16.4119 3.19486 15.7287 3 15 3H13Z" />
      <path d="M18 21V23H19C20.1046 23 21 22.1046 21 21V19H19V21H18Z" />
      <path d="M7 21V23H5C3.89543 23 3 22.1046 3 21V20H5V21H7Z" />
      <path d="M5 15H3V19H5V15Z" />
      <path d="M12 3V1H8V3H12Z" />
      <path d="M19.4853 3.01471C20.4276 4.07445 21 5.47039 21 7.00002V8.00002H19V7.00002C19 6.02275 18.6495 5.12733 18.0674 4.4326L19.4853 3.01471Z" />
      <path d="M12 21H8V23H12V21Z" />
      <path d="M5 5L5 9H3V5H5Z" />
      <path d="M17 21H13V23H17V21Z" />
      <path d="M21 9V13H19V9H21Z" />
      <path d="M19 18H21V14H19V18Z" />
      <path d="M11 7H7V9H11V7Z" />
      <path d="M15 14V12H7V14H15Z" />
      <path d="M15 16V18H7V16H15Z" />
    </svg>
  );
};

export default DocDraftIcon;
