import { useEffect } from "react";
import _ from "lodash";
import { useRouter } from "next/router";

import useSnackbar from "./use-snackbar";

interface FailedIntegration {
  messageTitle?: string;
  prependMessage?: string;
  defaultMessage: string;
  errorsToIgnore?: Array<string>;
}

const failedIntegrations: { [key: string]: FailedIntegration } = {
  Stripe: {
    messageTitle: "Stripe integration incomplete",
    prependMessage:
      "Your Stripe account has not yet been successfully integrated",
    defaultMessage: "Unknown error authorizing your Stripe account.",
    errorsToIgnore: ["The user denied your request"],
  },
  Google: {
    defaultMessage: "Unknown error authorizing your Google account.",
  },
  Microsoft: {
    defaultMessage: "Unknown error authorizing your Microsoft account.",
  },
};

const successfulIntegrations: { [key: string]: string } = {
  Calendar: "You have succesfully authorized your Google Calendar.",
  Email: "You have succesfully authorized your Google Email",
  Stripe: "You have succesfully authorized your Stripe Account",
};

export default function useFailedIntegrationMonitor(): void {
  const router = useRouter();
  const snackbar = useSnackbar();

  const { failedIntegration, message, successfulIntegration } = router.query;
  const failedIntegrationName = _.isString(failedIntegration)
    ? failedIntegration
    : _.first(failedIntegration);

  const successfulIntegrationName = _.isString(successfulIntegration)
    ? successfulIntegration
    : _.first(successfulIntegration);

  useEffect(() => {
    const messageFromQuery = _.isString(message) ? message : _.first(message);
    if (
      failedIntegrationName &&
      !_.isEmpty(failedIntegrationName) &&
      !_.includes(
        failedIntegrations[failedIntegrationName]?.errorsToIgnore,
        messageFromQuery
      )
    ) {
      const messageToShow = messageFromQuery
        ? _.chain([
            failedIntegrations[failedIntegrationName]?.prependMessage,
            messageFromQuery,
          ])
            .compact()
            .join(": ")
            .value()
        : failedIntegrations[failedIntegrationName].defaultMessage;

      const messageTitle =
        failedIntegrations[failedIntegrationName]?.messageTitle ||
        `${failedIntegrationName} Integration fail`;
      snackbar.showWarning(messageTitle, messageToShow);
    }
  }, [failedIntegrationName, message]);

  useEffect(() => {
    const messageToShow = _.isString(message)
      ? message
      : _.first(message) ||
        (successfulIntegrationName
          ? successfulIntegrations[successfulIntegrationName]
          : undefined);

    if (!_.isEmpty(successfulIntegrationName)) {
      snackbar.showMessage(
        `${successfulIntegrationName} Integration Successful`,
        messageToShow
      );
    }
  }, [successfulIntegrationName, message]);
}
