import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const GroupApptIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2H7V4H15V2H17V4H20V7.5C20 7.77614 19.7761 8 19.5 8H18.5C18.2239 8 18 7.77614 18 7.5V6H4V20H5.5C5.77614 20 6 20.2239 6 20.5V21.5C6 21.7761 5.77614 22 5.5 22H4C2.89543 22 2 21.1046 2 20V4H5V2ZM14.9522 12.5632C14.7529 11.6687 13.9546 11 13 11C11.8954 11 11 11.8954 11 13C11 14.1046 11.8954 15 13 15C13.0428 15 13.0851 14.9987 13.127 14.9961C13.3935 13.9653 14.0605 13.0957 14.9522 12.5632ZM16.8745 12.0019C16.4311 10.2757 14.8645 9 13 9C10.7909 9 9 10.7909 9 13C9 15.2091 10.7909 17 13 17C13.042 17 13.0838 16.9994 13.1255 16.9981C13.2168 17.3535 13.3558 17.6899 13.5351 18H9C8.44772 18 8 18.4477 8 19V20V23H10V20H17C19.2091 20 21 18.2091 21 16C21 13.7909 19.2091 12 17 12C16.958 12 16.9162 12.0006 16.8745 12.0019ZM17 14C18.1046 14 19 14.8954 19 16C19 17.1046 18.1046 18 17 18C15.8954 18 15 17.1046 15 16C15 14.8954 15.8954 14 17 14ZM21 21C21.5523 21 22 21.4477 22 22V23H12V22C12 21.4477 12.4477 21 13 21H21Z"
    />
  </svg>
);

export default GroupApptIcon;
