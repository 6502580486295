import { FC, ReactElement, useState } from "react";

interface RemoteFaviconProps {
  iconUrl: string;
  placeholderIcon: ReactElement;
  className?: string;
}

const RemoteFavicon: FC<RemoteFaviconProps> = ({
  iconUrl,
  placeholderIcon,
  className,
}) => {
  const [failedToLoad, setFailedToLoad] = useState(false);

  if (failedToLoad && placeholderIcon) {
    return placeholderIcon;
  }

  return (
    <img
      className={className}
      src={iconUrl}
      alt="Icon"
      onError={() => {
        setFailedToLoad(true);
      }}
    />
  );
};

export default RemoteFavicon;
