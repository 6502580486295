import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const FilledFlagIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.0365 13.4332C11.4856 14.1894 10.0832 14.8732 7 14.4085V19.5C7 19.7761 6.77614 20 6.5 20H5.5C5.22386 20 5 19.7761 5 19.5V4.6404C5 4.31512 5.309 4.07757 5.62644 4.14859C9.69993 5.06004 11.2722 4.29348 13.0365 3.43324C14.3646 2.78569 15.8016 2.08507 18.4963 2.00716C18.7724 1.99918 19 2.22387 19 2.50001V11.5C19 11.7762 18.7724 11.9992 18.4963 12.0072C15.8016 12.0851 14.3646 12.7857 13.0365 13.4332Z" />
  </svg>
);

export default FilledFlagIcon;
