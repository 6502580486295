import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8404 21V18.012H10.2124V21H12.8404ZM11.7124 5.07599C13.6204 5.07599 14.6764 6.04799 14.6764 7.72799V7.73999C14.6764 8.96399 14.1844 9.73199 13.2484 10.476L12.2644 11.304C10.6564 12.588 10.3444 13.404 10.3444 15.816H12.6844C12.6844 14.064 13.0444 13.512 14.4364 12.408L15.3724 11.628C16.5604 10.692 17.3884 9.29999 17.3884 7.63199V7.61999C17.3884 4.77599 15.3124 3.02399 11.7244 3.02399C8.35244 3.02399 6.12044 5.01599 6.09644 8.05199H8.70044C8.70044 6.16799 9.88844 5.07599 11.7124 5.07599Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;
