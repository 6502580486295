import React, { FC, useMemo } from "react";
import classNames from "classnames";

import { FormattedPaginatedAppointment } from "@lib/utils/appointments/formatAppointment";

import AppointmentListItem from "./appointments/AppointmentListItem";
import CalendarIcon from "./Icons/CalendarIcon";
import EmptyList from "./EmptyList";
import { Trigger } from "./Trigger";

type VariantTypes = "upcoming" | "past";

interface CommonTypes {
  variant?: VariantTypes;
  showContacts?: boolean;
  onShare: (appt: FormattedPaginatedAppointment) => void;
  shouldDisplayOrganizer?: boolean;
  organizationId: string;
}

interface AppointmentGroupProps extends CommonTypes {
  groupName: string;
  groupType?: string;
  appointments: FormattedPaginatedAppointment[];
}

interface AppointmentListContainerProps extends CommonTypes {
  appointments: { [k: string]: FormattedPaginatedAppointment[] };
  emptyListDescription?: string;
  emptyListButtonAction?: () => void;
  group?: string;
  hideButton?: boolean;
  isEventFilter?: boolean;
  loadMore: () => void;
  isLoading: boolean;
}

const AppointmentGroup: FC<AppointmentGroupProps> = ({
  groupName,
  groupType,
  appointments,
  showContacts,
  variant,
  onShare,
  organizationId,
  shouldDisplayOrganizer,
}) => {
  return appointments.length > 0 ? (
    <div className="flex flex-col mb-6">
      <h4 className="text-gray-500 mb-2">{groupName}</h4>
      <ul>
        {appointments
          .sort((appA, appB) => {
            // for upcoming, sort ascending. for past sort descending
            if (groupType === "upcoming") {
              return appB.ISOstart < appA.ISOstart ? 1 : -1;
            } else {
              return appB.ISOstart < appA.ISOstart ? -1 : 1;
            }
          })
          .map((appointment) => (
            <AppointmentListItem
              organizationId={organizationId}
              key={appointment.id}
              appointment={appointment}
              showContact={showContacts}
              variant={variant}
              onShare={onShare}
              shouldDisplayOrganizer={shouldDisplayOrganizer}
            />
          ))}
      </ul>
    </div>
  ) : null;
};

const AppointmentListContainer: FC<AppointmentListContainerProps> = ({
  appointments,
  emptyListDescription,
  showContacts,
  emptyListButtonAction,
  variant,
  group,
  onShare,
  hideButton = false,
  isEventFilter,
  shouldDisplayOrganizer,
  organizationId,
  loadMore,
  isLoading,
}) => {
  const isEmpty = useMemo(() => {
    const appointmentsKeys = Object.keys(appointments) ?? [];
    const allEmpty = appointmentsKeys.every(
      (key) => appointments[key].length === 0
    );
    return appointmentsKeys.length === 0 || allEmpty;
  }, [appointments]);
  const defaultEmptyListDescription = isEventFilter
    ? "Your events will appear here as soon as you create one"
    : "Your appointments will appear here as soon as a client makes a booking.";

  return (
    <div className={classNames("mt-6 mb-6 bg-white")}>
      {!isLoading && isEmpty ? (
        <EmptyList
          icon={<CalendarIcon />}
          description={emptyListDescription ?? defaultEmptyListDescription}
          buttonAction={emptyListButtonAction}
          hideButton={hideButton}
        />
      ) : (
        <>
          {Object.keys(appointments).map((key: string) => (
            <AppointmentGroup
              groupName={key}
              groupType={group}
              appointments={appointments[key]}
              showContacts={showContacts}
              variant={variant}
              key={key}
              organizationId={organizationId}
              onShare={onShare}
              shouldDisplayOrganizer={shouldDisplayOrganizer}
            />
          ))}
          <Trigger isLoading={isLoading} onIntersection={() => loadMore()} />
        </>
      )}
    </div>
  );
};

export default AppointmentListContainer;
