import moment from "moment";

import { FormattedPaginatedAppointment } from "./formatAppointment";

export type Appointment = FormattedPaginatedAppointment;

export class GroupedAppointments {
  private _upcoming: {
    Today: Appointment[];
    Tomorrow: Appointment[];
    "Next 7 days": Appointment[];
    [key: string]: Appointment[];
  };
  private _past: {
    [key: string]: Appointment[];
  };

  get upcoming(): typeof this._upcoming {
    return this._upcoming;
  }

  get past(): typeof this._past {
    return this._past;
  }

  constructor() {
    this._upcoming = {
      Today: [],
      Tomorrow: [],
      "Next 7 days": [],
    };
    this._past = {};
  }

  add(appointment: Appointment) {
    const formattedMonth = moment(appointment.ISOstart).format("MMM YYYY");
    // Group appointments by past and upcoming. Within 'past', group by month. Within upcoming
    // group by 'Today', 'Tomorrow', 'Next 7 days', and rest by month
    if (
      // Remove 30 min from current time as buffer to display ongoing appointments in upcoming tab
      moment(appointment.ISOstart).isBefore(moment().subtract(30, "minutes"))
    ) {
      this.addToPast(formattedMonth, appointment);
    } else {
      const appointmentStartDate = moment(appointment.ISOstart);

      // Today grouping
      if (appointmentStartDate.isSame(moment(), "day")) {
        this.addToUpcoming("Today", appointment);
        // Tomorrow
      } else if (appointmentStartDate.isSame(moment().add(1, "days"), "day")) {
        this.addToUpcoming("Tomorrow", appointment);
        // Next 7 days
      } else if (
        appointmentStartDate.isBetween(moment(), moment().add(7, "days"), "day")
      ) {
        this.addToUpcoming("Next 7 days", appointment);
        // By month
      } else {
        this.addToUpcoming(formattedMonth, appointment);
      }
    }
  }

  private addToPast(key: string, val: Appointment) {
    this.addToArrayInObjectIfExists(this._past, key, val);
  }

  private addToUpcoming(
    key: "Today" | "Tomorrow" | "Next 7 days" | string,
    val: Appointment
  ) {
    this.addToArrayInObjectIfExists(this._upcoming, key, val);
  }

  private addToArrayInObjectIfExists(
    obj: { [key: string]: Appointment[] },
    key: string,
    val: Appointment
  ) {
    obj[key] ? (obj[key] = [...obj[key], val]) : (obj[key] = [val]);
  }
}

export function groupAppointments(
  formattedAppointments: Appointment[]
): GroupedAppointments {
  const groupedAppointments = new GroupedAppointments();

  formattedAppointments.forEach((appointment) =>
    groupedAppointments.add(appointment)
  );

  return groupedAppointments;
}
