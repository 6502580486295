import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2H16V4H8.00002V2H6.00002V4H3.00002V8.5C3.00002 8.77614 3.22387 9 3.50002 9H4.50002C4.77616 9 5.00002 8.77614 5.00002 8.5V6H19V20H16.5C16.2239 20 16 20.2239 16 20.5V21.5C16 21.7761 16.2239 22 16.5 22H19C20.1046 22 21 21.1046 21 20V4H18V2ZM15.0607 17.0962C15.2559 16.9009 15.2559 16.5843 15.0607 16.3891L10.818 12.1464C10.6228 11.9512 10.3062 11.9512 10.1109 12.1464L9.40381 12.8536C9.20855 13.0488 9.20855 13.3654 9.40381 13.5607L11.5858 15.7426H3.5C3.22386 15.7426 3 15.9665 3 16.2426V17.2426C3 17.5188 3.22386 17.7426 3.5 17.7426H11.5858L9.40381 19.9246C9.20855 20.1199 9.20855 20.4365 9.40381 20.6317L10.1109 21.3388C10.3062 21.5341 10.6228 21.5341 10.818 21.3388L15.0607 17.0962Z"
      />
    </svg>
  );
};

export default Icon;
