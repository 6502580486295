import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const FailedIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8716 5.54978C12.4893 4.87012 11.5107 4.87013 11.1284 5.54978L3.83825 18.51C3.46329 19.1767 3.945 20.0003 4.70983 20.0003H19.2901C20.055 20.0003 20.5367 19.1767 20.1617 18.51L12.8716 5.54978ZM11 10.5003C11 10.2242 11.2238 10.0003 11.5 10.0003H12.5C12.7761 10.0003 13 10.2242 13 10.5003V14.5003C13 14.7765 12.7761 15.0003 12.5 15.0003H11.5C11.2238 15.0003 11 14.7765 11 14.5003V10.5003ZM11 17.0003C11 16.448 11.4477 16.0003 12 16.0003C12.5523 16.0003 13 16.448 13 17.0003C13 17.5526 12.5523 18.0003 12 18.0003C11.4477 18.0003 11 17.5526 11 17.0003Z"
      />
    </svg>
  );
};

export default FailedIcon;
