import React, { useState } from "react";
import classNames from "classnames";

import { getThumbnailFromUrl } from "@lib/utils";

function FilePreview({ url, size = 600, fallback, className, imgClassName }) {
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(false);

  if (error && fallback) {
    return fallback;
  }

  return (
    <div
      className={classNames(
        "hover:bg-none",
        className,
        !loaded && "image-placeholder"
      )}
    >
      <img
        className={classNames(
          "m-auto hover:opacity-40 w-full",
          loaded && imgClassName
        )}
        onError={(e) => setError(e)}
        onLoad={() => setLoaded(true)}
        src={getThumbnailFromUrl(url, size)}
      />
    </div>
  );
}

export default FilePreview;
