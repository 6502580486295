import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const SharedIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 4a8 8 0 00-5.968 13.328.525.525 0 01-.009.72l-.707.707a.481.481 0 01-.696-.007A9.964 9.964 0 012 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 2.563-.964 4.9-2.549 6.67a.481.481 0 01-.7.01l-.707-.707a.525.525 0 01-.014-.716A8 8 0 0012 4z"></path>
      <path d="M8 12a4 4 0 117.186 2.419c-.166.218-.167.53.027.723l.709.709a.47.47 0 00.694-.018 6 6 0 10-9.162.083.47.47 0 00.69.011l.705-.705c.197-.197.192-.516.019-.734A3.983 3.983 0 018 12z"></path>
      <path d="M10 12a2 2 0 104 0 2 2 0 00-4 0z"></path>
    </svg>
  );
};

export default SharedIcon;
