import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ShareIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.6465 1.93942C11.8417 1.74415 12.1583 1.74415 12.3536 1.93942L15.8536 5.43942C16.0488 5.63468 16.0488 5.95126 15.8536 6.14652L15.1465 6.85363C14.9512 7.04889 14.6346 7.04889 14.4394 6.85363L13 5.41427V13.5C13 13.7762 12.7762 14 12.5 14H11.5C11.2239 14 11 13.7762 11 13.5V5.4143L9.56069 6.85363C9.36543 7.04889 9.04885 7.04889 8.85358 6.85363L8.14648 6.14652C7.95122 5.95126 7.95121 5.63468 8.14648 5.43942L11.6465 1.93942Z" />
      <path d="M8.5 8C8.77614 8 9 8.22386 9 8.5V9.5C9 9.77614 8.77614 10 8.5 10H7V19H17V10H15.5C15.2239 10 15 9.77614 15 9.5V8.5C15 8.22386 15.2239 8 15.5 8H18C18.5523 8 19 8.44772 19 9V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V9C5 8.44772 5.44772 8 6 8H8.5Z" />
    </svg>
  );
};

export default ShareIcon;
