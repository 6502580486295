import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.4042 4.01003L15.3536 2.06066C15.5488 1.8654 15.5488 1.54882 15.3536 1.35355L14.6465 0.646446C14.4512 0.451184 14.1346 0.451185 13.9394 0.646447L9.93933 4.64647C9.74406 4.84174 9.74406 5.15832 9.93933 5.35358L13.9394 9.35356C14.1346 9.54882 14.4512 9.54882 14.6465 9.35356L15.3536 8.64644C15.5488 8.45118 15.5488 8.1346 15.3536 7.93934L13.4293 6.01512C16.5427 6.23524 19 8.83065 19 12C19 13.6352 18.3472 15.1159 17.2858 16.1991C16.8992 16.5935 16.9056 17.2267 17.3001 17.6132C17.6946 17.9998 18.3277 17.9933 18.7142 17.5989C20.1272 16.157 21 14.1794 21 12C21 7.71727 17.6347 4.22055 13.4042 4.01003Z" />
      <path d="M10.5706 17.9849L8.64643 16.0607C8.45117 15.8654 8.45117 15.5488 8.64643 15.3536L9.35354 14.6464C9.5488 14.4512 9.86538 14.4512 10.0606 14.6464L14.0607 18.6465C14.2559 18.8417 14.2559 19.1583 14.0607 19.3536L10.0606 23.3536C9.86538 23.5488 9.5488 23.5488 9.35354 23.3536L8.64643 22.6464C8.45117 22.4512 8.45118 22.1346 8.64644 21.9393L10.5958 19.99C6.36535 19.7795 3 16.2827 3 12C3 10.0425 3.70431 8.24696 4.87222 6.85676C5.22747 6.4339 5.85826 6.37908 6.28112 6.73433C6.70399 7.08958 6.7588 7.72037 6.40355 8.14324C5.52705 9.18657 5 10.5304 5 12C5 15.1693 7.45733 17.7647 10.5706 17.9849Z" />
    </svg>
  );
};

export default Icon;
