import { FC, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { getDashboard } from "api-services/definitions/user";
import { useApiGet } from "api-services/endpoints";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import { Appointment } from "@hooks/use-appointments";
import { useListConfiguration } from "@hooks/use-list-configuration";
import useMemberFilterOptions from "@hooks/use-member-filter-options";
import usePaginatedAppointments from "@hooks/use-paginated-appointments";
import analytics from "@lib/analytics";
import { FormattedPaginatedAppointment } from "@lib/utils/appointments/formatAppointment";
import { Appointment as GroupAppointment } from "@lib/utils/appointments/groupAppointments";
import { getArtifactLink } from "@lib/utils/artifacts";

import CalendarIcon from "./Icons/CalendarIcon";
import CalendarArrowIcon from "./Icons/EventBeforeIcon";
import EventsIcon from "./Icons/EventsIcon";
import SharingModal from "./Modals/SharingModal";
import AppointmentListContainer from "./AppointmentListContainer";
import { Button } from "./Button";
import { FilterByItemType } from "./SortDropdown";

type AppointmentListType = {
  showOnlyUpcoming?: boolean;
  title?: string;
};

const AppointmentList: FC<AppointmentListType> = ({
  title,
  showOnlyUpcoming = false,
}) => {
  const router = useRouter();
  const query = router.query;

  const { hasFullAccess } = useAccessType();

  const [selectedTab, setSelectedTab] = useState<"upcoming" | "past">(
    (query?.tab as "upcoming" | "past") ?? "upcoming"
  );
  const [selectedApptShareModal, setSelectedApptShareModal] =
    useState<FormattedPaginatedAppointment | null>(null);

  const { organization, oid } = useAuth();

  const renderBadgeTotal = (value: number) => (
    <span className="bg-grey-950 ml-2 font-medium text-black-ink text-sm px-2 py-1 rounded">
      {value}
    </span>
  );

  const isSelectedTabPast = selectedTab === "past";
  const { data } = useApiGet(
    getDashboard,
    {
      userId: oid ?? "",
    },
    { upcoming: `${!isSelectedTabPast}` }
  );

  const { options: memberFilterOptions } = useMemberFilterOptions();

  const { SortDropdown, filtersMap, selectedSort } =
    useListConfiguration<Appointment>(
      [],
      [
        {
          text: "All",
          value: "all",
          icon: <CalendarIcon />,
          transform: (items) => {
            return items;
          },
        },
        {
          text: "Appointments",
          textRight: renderBadgeTotal(data?.totalAppointments ?? 0),
          value: "appointments",
          icon: <CalendarArrowIcon />,
          transform: (items) => {
            return items;
          },
        },
        {
          text: "Events",
          textRight: renderBadgeTotal(data?.totalEvents ?? 0),
          value: "events",
          icon: <EventsIcon />,
          transform: (items) => {
            return items;
          },
        },
      ],
      [
        {
          text: "Show hidden appts",
          transform: (items, _value) => items,
          key: "showHiddenAppts",
          defaultValue: false,
        },
        {
          text: "Without outcome",
          transform: (items, _value) => items,
          key: "withoutOutcome",
          defaultValue: false,
        },
        ...(memberFilterOptions && hasFullAccess
          ? ([
              {
                text: "Visibility",
                transform: (items: any[], _value: string) => items,
                options: memberFilterOptions,
                key: "visibility",
                defaultValue: "all",
              },
            ] as FilterByItemType[])
          : []),
      ],
      `appointmentsListConfiguration`,
      "Filter by"
    );

  const onlyEvents = selectedSort === "events";
  const memberId =
    filtersMap.visibility !== "all" ? filtersMap.visibility : undefined;
  const showHidden = filtersMap.showHiddenAppts === true;
  const withoutOutcome = filtersMap.withoutOutcome === true;

  const {
    loading: isLoadingAppointments,
    loadMore,
    groupedAppointments,
  } = usePaginatedAppointments({
    oid,
    isPast: showOnlyUpcoming ? false : isSelectedTabPast,
    onlyEvents: onlyEvents,
    memberId: memberId ?? undefined,
    showHidden,
    withoutOutcome,
  });

  const updateRoute = (key: string, value: string) => {
    router.replace({
      query: { ...router.query, [key]: value },
    });
  };

  const selectUpcomingTab = () => {
    setSelectedTab("upcoming");
    updateRoute("tab", "upcoming");
  };
  const selectPastTab = () => {
    setSelectedTab("past");
    updateRoute("tab", "past");
  };

  const handleShare = (item: FormattedPaginatedAppointment) => {
    setSelectedApptShareModal(item);
    analytics.track("group-session_coach_share-modal-open");
  };

  const renderShareModal = () => {
    if (!selectedApptShareModal) return;

    const copyUrl = getArtifactLink(
      organization ?? {},
      selectedApptShareModal,
      "book-event"
    );

    return (
      !!selectedApptShareModal && (
        <SharingModal
          show={!!selectedApptShareModal}
          toggleShow={() => setSelectedApptShareModal(null)}
          artefactType="event"
          artefactLink={copyUrl}
          artefactItem={selectedApptShareModal}
        />
      )
    );
  };

  const renderButtonTab = ({
    label,
    onClick,
    selected,
  }: {
    label: string;
    selected: string;
    onClick: () => void;
  }) => {
    const tabBtnDefaultClass =
      "hover:bg-action-950 focus:bg-action-900 text-xl font-normal";

    return (
      <Button
        small
        overrideColors
        onClick={onClick}
        className={classNames(
          tabBtnDefaultClass,
          "mr-2",
          selectedTab === selected
            ? "bg-action-900 text-black-ink"
            : "bg-white text-gray-500"
        )}
        disabled={isLoadingAppointments}
      >
        {label}
      </Button>
    );
  };

  const renderNavBar = !showOnlyUpcoming ? (
    <nav className="-mb-px flex justify-between items-center">
      <div className="flex flex-1">
        {renderButtonTab({
          label: "Upcoming",
          selected: "upcoming",
          onClick: selectUpcomingTab,
        })}
        {renderButtonTab({
          label: "Past",
          selected: "past",
          onClick: selectPastTab,
        })}
      </div>
      {SortDropdown}
    </nav>
  ) : (
    <div className="flex items-center">
      {title && (
        <h2 className="text-black-ink text-xl font-regular">{title}</h2>
      )}
      <div className="ml-auto">{SortDropdown}</div>
    </div>
  );

  const shouldDisplayOrganizer = filtersMap?.visibility === "all";

  const groupSortedByTab = (
    orderBy: "desc" | "asc"
  ): [string, GroupAppointment[]][] =>
    _.orderBy(
      Object.entries(groupedAppointments[selectedTab]),
      (item) => moment(item[0], "MMM YYYY"),
      [orderBy]
    );

  return (
    <>
      {renderNavBar}
      <AppointmentListContainer
        organizationId={oid!}
        appointments={
          showOnlyUpcoming
            ? groupedAppointments.upcoming
            : Object.fromEntries(
                groupSortedByTab(isSelectedTabPast ? "desc" : "asc")
              )
        }
        showContacts={true}
        group={showOnlyUpcoming ? "upcoming" : selectedTab}
        onShare={handleShare}
        isEventFilter={selectedSort === "events"}
        hideButton
        shouldDisplayOrganizer={shouldDisplayOrganizer}
        isLoading={isLoadingAppointments}
        loadMore={loadMore}
      />
      {renderShareModal()}
    </>
  );
};

export default withErrorBoundary(AppointmentList, {
  fallback: (
    <div className="flex w-100 p-12 justify-center">
      Failed to load appointments.
    </div>
  ),
});
